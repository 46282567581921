body#pricelist
{
	nav.main_navbar
	{
		ul
		{
			li.pricelist
			{
				.active-link();
			}
		}
	}

	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.pricelist
			{
				.active-link();
			}
		}
	}

	section.content-wrapper
	{
		padding-top: 16px;

		.seasons-wrapper, .closed_accommodation_periods-wrapper
		{
			margin: 0 0 32px;

			ul
			{
				li
				{
					padding: 12px 0;
				}
			}
		}

		.main_content_wrapper
		{
			margin: -16px 0 0;

			.table-heading
			{
				margin-top: 16px;
			}

			.table-responsive
			{
				table
				{
					border: 1px solid @secondary_color;
					margin: 0 0 16px;

					th, td
					{
						border:  1px solid @secondary_color;
						padding: 8px;
					}

					thead
					{
						*
						{
							background-color: @secondary_color_light;
							text-align:       center;
						}
					}

					tbody
					{
						tr
						{
							> *
							{
								&:not(:first-child)
								{
									text-align: right;
								}
							}
						}
					}
				}
			}

			div.table-room_prices-wrapper
			{
				table#table-room_prices
				{
					tr
					{
						.td-room_name
						{
							width: 500px;
						}

						.td-room_price
						{
							width: 100px;
						}
					}
				}
			}

			.table-small-wrapper
			{
				table.table-small
				{
					tr
					{
						.td-main_row
						{
							width: 200px;
						}
					}
				}

				table#table-surcharges
				{
					td, th
					{
						width: 50%;
					}
				}
			}

			.price_comment-wrapper
			{
				//flex: 1 1 auto;
				flex-basis: auto;
				margin:     16px 0;
			}
		}


	}

}

