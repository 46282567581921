// .active-link(){
//   border-top: 3px solid @primary_color;
//   border-bottom: 0;
//   background-color: white;
// }
.active-link()
{
	*
	{
		font-weight: 700;
		color:       @primary_color;
	}
}

.contact-heading-small()
{
	font-weight: 700;
	color:       @primary_color;
	font-size:   24px;
	margin:      0 0 8px;
}
