body.rooms, body.room_list, body.room_detail
{

	.content-wrapper
	{
		@media (max-width: 767px)
		{
			flex-wrap: wrap;
		}

		.room-navigation
		{
			min-width:        300px;
			flex-basis:       300px;
			background-color: white;
			border-right:     1px solid @primary_color;
			@media (max-width: 767px)
			{
				flex-basis: 100%;
				margin:     0;
				// top: @navbar-height-total;
			}

			.room-navigation-heading-wrapper
			{
				background-color: @secondary_color;
				padding:          16px;
				display:          flex;
				justify-content:  space-between;
				align-items:      center;
				align-content:    center;

				*
				{
					color: white;
				}

				.room-navigation-heading
				{
					font-size: 24px;
					width:     auto;
					margin:    0;
				}

				#room-navigation-toggle:checked + #room-navigation-toggle-label
				{
					.room-navigation-toggle-icon
					{
						transform: rotateX(180deg);
					}
				}

				#room-navigation-toggle-label
				{
					margin:     0 0 0 16px;
					display:    flex;
					cursor:     pointer;
					flex-basis: @room_list-navbar-toggle-icon-size;
					height:     @room_list-navbar-toggle-icon-size;

					.room-navigation-toggle-icon
					{
						display:    inline-block;
						font-size:  @room_list-navbar-toggle-icon-size;
						transition: 1s ease-out;
					}

				}
			}

			.room-navigation-link_list-wrapper
			{
				.room-navigation-link_list
				{
					padding: 16px;
					width:   100%;
					margin:  0;

					.room-navigation-link_list-item
					{
						margin:  0;
						display: block;

						.room-navigation-link
						{
							display: block;
							margin:  0;
						}

						.active-link
						{
							font-weight: 700;
						}
					}
				}
			}
		}

		#room-navigation-toggle:checked + .room-navigation
		{
			left: -300px + @room_list-navbar-toggle-icon-size + 32px;

			.room-navigation-toggle-icon
			{
				transition: 1s ease-out;
			}
		}

		#room-navigation-toggle:not(:checked) + .room-navigation
		{
			left: 0;

			.room-navigation-toggle-icon
			{
				transform:  rotateX(180deg);
				transition: 1s ease-out;
			}
		}
	}
}
