*
{
	box-sizing: border-box;
}

html
{
	height:     100%;
	overflow-x: hidden;
	
	body
	{
		background-color: @background-color;
		display:          flex;
		flex-direction:   column;
		height:           100%;
		// align-content: flex-start;
		font-family:      @primary_font;
		font-weight:      500;
		
		.page_wrapper
		{
			position:  relative;
			flex:      1 1 auto;
			height:    auto;
			display:   flex;
			flex-flow: column nowrap;
			
			.content-wrapper
			{
				flex:           1 1 auto;
				display:        flex;
				flex-direction: column;
				align-items:    stretch;
			}
		}
		
		.validation-error
		{
			color: red;
		}
		
		p, a, span
		{
			// color: @primary_color;
			color: black;
			
		}
		
		a
		{
			text-decoration: none;
			color:           inherit;
			
			&:hover
			{
				text-decoration: none;
			}
		}
		
		// background-color: @background-color;
		// header{
		//   flex-basis: 100%;
		//   display: flex;
		//   justify-content: space-between;
		//   align-items: flex-start;
		//   h1{
		//     font-size: 36px;
		//     margin: 20px;
		//   }
		//   .contact-wrapper{
		//     margin: 20px;
		//     h4{
		//       margin: 0;
		//       text-align: right;
		//       font-size: 16px;
		//       &:first-of-type{
		//         margin: 0 0 10px;
		//       }
		//     }
		//   }
		// }
	}
}
