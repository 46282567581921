body
{
	header.booking-header
	{
		border-bottom: 2px solid @primary_color;

		.logo-wrapper
		{
			max-height: 100px;
		}
	}
}
