@footer-text-color: white;
@footer-navbar-active_link-font_weight: 700;
footer.footer_wrapper
{
	border-top:       3px solid @secondary_color;
	background-color: @secondary_color_light;

	.footer-fade_out
	{
		z-index:          1;
		pointer-events:   none;
		background-image: linear-gradient(white, @background-color);
		height:           16px;
		flex-basis:       100%;
	}

	> .footer-content
	{
		padding: 32px 16px 0;

		.footer-subheading
		{
			color:       @primary_color;
			font-weight: 600;
		}

		.footer-accommodation-address-wrapper, .footer-accommodation-contact-wrapper
		{
			margin-bottom: 32px;

			.footer-contact-item
			{
				display:       flex;
				margin:        16px 0 0;
				align-content: center;
				align-items:   center;

				i
				{
					color:           @secondary_color;
					display:         inline-flex;
					justify-content: center;
					flex-basis:      16px;
					margin-right:    16px;
				}

				span.footer-item-value
				{
					flex-grow:   1;
					font-weight: 600;
				}
			}
		}
	}
}
