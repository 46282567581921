.text-primary_color
{
	color: @primary_color;
}

.text-secondary_color
{
	color: @secondary_color;
}

.background-primary_color
{
	background-color: @primary_color;
}

.background-secondary_color
{
	background-color: @secondary_color;
}

.error-description
{
	color: red;
}

.display-none
{
	display: none;
}

.main_content-table.show_accommodation_info
{
	margin: 0 0 20px;
}

.buttons-wrapper
{
	margin: 0 0 20px;
}

.validation-error, .error-description
{
	color: red;
}

.mx-15px
{
	margin-right: 15px;
	margin-left:  15px;
}

.error-message
{
	color: red;
}

.btn
{
	cursor: pointer;
}

.row
{
	margin:  0;
	padding: 0 8px;
	
	> *
	{
		// padding-right: 0;
		// padding-left: 16px;
		padding: 0 8px;
	}
}

// .row-adjusted {
//   // padding-right: 16px;
//   padding: 0 8px;
//   > * {
//     // padding-right: 0;
//     // padding-left: 16px;
//     padding: 0 8px;
//   }
// }

.row-marginless
{
	padding: 0;
	margin:  0 -8px;
}

.container, .container-fluid
{
	padding: 0 16px;
}

.input-hidden
{
	width:    0.1px;
	height:   0.1px;
	opacity:  0;
	overflow: hidden;
	position: absolute;
	z-index:  -1;
}
