.control_buttons
{
	display:         flex;
	justify-content: space-evenly;
}

.cursor-pointer
{
	cursor: pointer;
}

.table-last_columns-text-align-right
{
	tr
	{
		td, th
		{
			&:last-child
			{
				text-align: right;
			}

			&:first-child
			{
				text-align: left;
			}
		}
	}
}

table.table-vertical_align-middle
{
	th, td
	{
		vertical-align: middle;
	}
}

.display-none
{
	display: none;
}

.display-table
{
	display: table;
}

.max-height-0
{
	height: 0;
}

.max-height-auto
{
	height: auto;
}

.radio-field
{
	margin: 8px 0 0 32px;
}
