body.rooms.room_detail
{
	.h3-style()
	{
		text-align: center;
		margin:     32px 0 16px;
		font-size:  28px;
		color:      @secondary_color_dark;
	}

	.content-wrapper
	{
		flex-direction: row;
		padding:        0;

		.room_detail-wrapper
		{
			flex-grow: 1;
			padding:   0;

			.room_detail-main_image-wrapper
			{
				position: relative;

				.room_detail-main_image-overlay
				{
					display:          flex;
					justify-content:  center;
					align-content:    center;
					align-items:      center;
					position:         absolute;
					top:              0;
					left:             0;
					height:           100%;
					width:            100%;
					background-color: fade(black, 35%);
					color:            white;

					.room_detail-room_name
					{
						font-size: 48px;
					}
				}
			}

			.room_detail-content-wrapper
			{
				padding: 16px;

				*
				{
					font-size: 16px;
				}

				.room_detail-content-room_attributes-wrapper
				{
					border-bottom: 1px solid @secondary_color;

					.room_detail-content-room_attribute
					{
						display:       flex;
						border:        1px solid @secondary_color;
						border-bottom: none;

						> div
						{
							padding: 8px;
						}

						> div:first-of-type
						{
							flex-basis:   70%;
							border-right: 1px solid @secondary_color;
						}

						> div:last-of-type
						{
							flex-basis: 30%;
						}
					}
				}

				.room_detail-content-room_description-wrapper
				{
					.room_detail-content-room_description-heading
					{
						font-weight: 700;
						color:       @secondary_color_dark;
					}

					p.room_detail-room_description
					{

					}
				}

				.room_detail-content-equipment_list-heading
				{
					// .h3-style();
					font-weight: 700;
					color:       @secondary_color_dark;
				}

				.room_detail-content-equipment_list-wrapper
				{
					i
					{
						color: @primary_color;
					}
				}
			}

			.room_detail-room_image_list-wrapper
			{
				.room_detail-room_image_list-heading
				{
					.h3-style();
				}

				> .room_detail-room_image_list
				{
					margin:       0 -1px;
					border:       1px solid rgba(0, 0, 0, 0.1);
					padding:      2px 0;
					border-left:  none;
					border-right: none;

					.parent_of-room_detail-room_image-wrapper
					{
						padding: 0 1px;

						.room_detail-room_image-wrapper
						{
							position: relative;

							.room_detail-room_image-link
							{
								display:  block;
								position: absolute;
								top:      0;
								left:     0;
								height:   100%;
								width:    100%;
								z-index:  10;
							}

							.room_detail-room_image
							{
								max-width: 100%;
								width:     100%;
								height:    auto;
								z-index:   5;
							}
						}
					}
				}
			}

			.room_detail-submit_button-wrapper
			{
				display:         flex;
				justify-content: center;

				a.btn
				{
					margin:           16px 0;
					font-size:        22px;
					background-color: @secondary_color;
					color:            white;
				}
			}

			.calendar-heading
			{
				.h3-style();
			}
		}
	}
}
