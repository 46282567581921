body#booking
{
	nav.main_navbar
	{
		ul
		{
			li.booking
			{
				.active-link();
			}
		}
	}

	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.booking
			{
				.active-link();
			}
		}
	}

	section
	{
		padding-top: 16px;

		.container
		{
			h2
			{
				text-align: center;
			}
		}

		//table
		//{
		//  td
		//  {
		//	text-align: right;
		//  }
		//}
		//
		//table.table-days
		//{
		//  th
		//  {
		//	text-align: right;
		//  }
		//}
	}
}
