.message-wrapper
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow:   1;
	height:      auto;
	> *
	{
		text-align: center;
	}
}

