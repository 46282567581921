nav.navbar-custom.main_navbar
{
	display:          flex;
	flex-wrap:        wrap;
	justify-content:  space-between;
	align-items:      center;
	flex-basis:       @navbar-height;
	box-sizing:       content-box;
	border-top:       @navbar-border-top-height solid @primary_color;
	background-color: @background-color;
	font-size:        18px;
	height:           @navbar-height;
	border-bottom:    @navbar-border-bottom-height solid @primary_color;
	@media (max-width: 1299px)
	{
		height: @navbar-height + @navbar-contact-collapsed-height;
	}
	
	> *
	{
		padding:          8px 16px;
		color:            @primary_color;
		background-color: @background-color;
	}
	
	.navbar-brand-custom
	{
		padding:       0 0 0 16px;
		margin:        0;
		display:       flex;
		align-content: center;
		align-items:   center;
		height:        @navbar-height;
		
		> *
		{
			color:       @secondary_color_dark;
			font-size:   32px;
			font-weight: 800;
		}
		
		.accommodation-logo
		{
			height:     @navbar-height - @navbar-border-bottom-height - @navbar-border-top-height;
			max-height: @navbar-height - @navbar-border-bottom-height - @navbar-border-top-height;
		}
	}
	
	.navbar-toggler
	{
		display:   block;
		border:    none;
		color:     @primary_color;
		padding:   0 16px;
		font-size: 36px;
	}
	
	.navbar-links
	{
		padding:          0;
		align-items:      stretch;
		background-color: @background-color;
		
		.navbar-nav
		{
			margin: 0 auto;
			@media (max-width: 1299px)
			{
				margin: 0 0 0 auto;
			}
			
			.nav-item
			{
				display:     inline-flex;
				align-items: center;
				
				.nav-link
				{
					font-weight: 600;
					flex-basis:  100%;
					padding:     8px 16px;
					color:       #484848;
					@media (min-width: 992px)
					{
						text-align: center;
					}
					@media (max-width: 991px)
					{
						text-align: left;
					}
					
					> i
					{
						display:         inline-flex;
						justify-content: center;
						width:           25px;
					}
				}
			}
		}
	}
	
	.contact-wrapper
	{
		display:    flex;
		padding:    0 16px 0 0;
		align-self: stretch;
		
		.contact
		{
			display:         flex;
			align-content:   center;
			align-items:     center;
			justify-content: flex-end;
			margin:          0;
			text-align:      right;
			font-weight:     700;
			
			i
			{
				margin-right: 8px;
			}
		}
		
		@media (min-width: 1300px)
		{
			flex-direction:  column;
			justify-content: space-evenly;
			.contact
			{
				> *
				{
					color: @secondary_color_dark;
				}
			}
		}
		@media (max-width: 1299px)
		{
			height:           @navbar-contact-collapsed-height;
			padding:          0 16px;
			flex-direction:   row;
			padding-top:      0;
			padding-bottom:   0;
			background-color: @primary_color;
			flex-basis:       100%;
			justify-content:  space-between;
			.contact
			{
				> *
				{
					color: white;
				}
			}
		}
	}
	
	.navbar-fade_out
	{
		z-index:          1;
		pointer-events:   none;
		background-image: linear-gradient(@background-color, white);
		width:            100%;
		height:           8px;
	}
	
	// .navbar-toggler{
	//   margin: 10px 20px;
	//   .navbar-toggler-icon{
	//     background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	//   }
	// }
	//
	// ul{
	//   flex-basis: 100%;
	//   justify-content: space-evenly;
	//   li{
	//     text-align: center;
	//     flex-grow: 1;
	//     flex-basis: 100/6%;
	//     border: 1px solid gray;
	//     border-left: 0;
	//     display: flex;
	//     &:last-of-type{
	//       border-right: 0;
	//     }
	//     @media (max-width: 767.98px) {
	//       border: 0;
	//       border-bottom: 1px solid gray;
	//       &:first-of-type{
	//         border-top: 1px solid gray;
	//       }
	//     }
	//     a.nav-link{
	//       font-size: 18px;
	//       width: 100%;
	//       height: 100%;
	//       padding: 15px;
	//     }
	//   }
	// }
}
