body.rooms
{
	nav.main_navbar
	{
		ul
		{
			li.rooms
			{
				.active-link();
			}
		}
	}
}

body.rooms.room_list
{
	section.content-wrapper
	{
		flex-direction: row;
		
		.room_list-wrapper
		{
			flex-grow:  1;
			padding:    16px 0 0;
			transition: 0.25s ease-out;
			
			.room-wrapper
			{
				position:      relative;
				border:        1px solid #333333;
				border-radius: 3px;
				margin:        0 0 16px;
				
				.room-name-wrapper
				{
					padding:          8px 16px;
					background-image: linear-gradient(rgb(214, 214, 212), rgb(255, 255, 255));
					
					.room-name
					{
						font-size:   24px;
						font-weight: 600;
						margin:      0;
						text-align:  center;
					}
				}
				
				.room-image-wrapper
				{
					padding: 0;
				}
				
				.room-info-wrapper
				{
					padding: 16px;
					
					.room-price-wrapper
					{
						.room-price
						{
							color:     @primary_color;
							font-size: 20px;
						}
					}
					
					.room-button-room_detail
					{
						width:            100%;
						background-color: @dark_color;
						text-align:       center;
						border:           none;
					}
				}
			}
		}
	}
	
	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.rooms
			{
				.active-link();
			}
		}
	}
}
