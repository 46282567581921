body#homepage
{

	nav.main_navbar
	{
		ul
		{
			li.homepage
			{
				.active-link();
			}
		}

		.navbar-fade_out
		{
			display: none;
		}
	}

	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.homepage
			{
				.active-link();
			}
		}
	}

	main
	{
		.carousel
		{
			// overflow-y: hidden;
			.carousel-item
			{
				// height: 100%;
				// width: 100vw;
				.overlay
				{
					align-items:      center;
					display:          flex;
					flex-direction:   column;
					justify-content:  center;
					position:         absolute;
					width:            100%;
					height:           100%;
					background-color: rgba(0, 0, 0, 0.5);
					color:            white;
					text-align:       center;
					right:            0;
					bottom:           0;
					left:             0;
					h5
					{
						font-size: 40px;
					}
					p
					{
						font-size: 25px;
					}
				}

				// img {
				//   // top: -600px;
				//   position: absolute;
				//   width: 100%;
				//   height: auto;
				// }
			}
		}

		.description
		{
			margin:     0 auto;
			font-size:  20px;
			max-width:  1600px;
			text-align: center;
			padding:    20px;
		}

		.description-title
		{
			margin:      0;
			font-weight: 600;
		}
	}
}
