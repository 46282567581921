body#gallery
{
	nav.main_navbar
	{
		ul
		{
			li.gallery
			{
				.active-link();
			}
		}
	}

	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.gallery
			{
				.active-link();
			}
		}
	}

	section
	{
		padding: 10px 0;

		.gallery-wrapper
		{
			padding-top: 16px;

			.image_section_wrapper
			{
				margin-bottom: 32px;

				.text_wrapper
				{
					.image_section_name
					{
						text-align: center;
					}

					.title_wrapper
					{
						text-align: center;
					}
				}

				// .big_images_wrapper {
				//   margin: 0 -17px;
				//   > div {
				//     padding: 0 1px;
				//   }
				// }
				// .small_images_wrapper.row {
				//   > div {
				//     margin-top: 16px;
				//   }
				// }
				.images_wrapper
				{
					margin:  0 -1px;
					padding: 0;

					> div
					{
						margin-top: 2px;
						padding:    0 1px;
					}
				}

				&:last-of-type
				{
					.image_section_split_line
					{
						display: none;
					}
				}
			}

			img.img-fluid
			{
				width: 100%;
			}
		}
	}
}
