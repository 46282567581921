.calendar
{
	height:          auto;
	display:         flex;
	flex-wrap:       wrap;
	font-size:       15px;
	text-align:      center;
	padding:         1px;
	justify-content: space-evenly;
	align-content:   space-between;

	.month
	{
		background-color: #E6E6E6;
		border:           1px solid black;
		margin:           1px;

		h3.month-name
		{
			padding:          5px;
			text-align:       center;
			background-color: lightgray;
			margin:           4px;
		}

		.day-name-wrapper
		{
			display: flex;
			padding: 2px;

			.day-name-item
			{
				display:         flex;
				justify-content: center;
				align-items:     center;
				width:           30px;
				height:          30px;
				margin:          2px;
			}
		}

		.date-wrapper
		{
			padding: 2px;

			.date-row
			{
				display: flex;

				.date-item
				{
					color:           white;
					display:         flex;
					justify-content: center;
					align-items:     center;
					position:        relative;
					width:           30px;
					height:          30px;
					margin:          2px;

					&:hover
					{
						cursor: pointer;
					}

					.date-item-number
					{
						position: absolute;
						z-index:  1000;
					}

					.date-item-triangles
					{
						position: absolute;
						top:      0;
						left:     0;
						opacity:  0.7;
						z-index:  900;
					}

					.date-item-triangles-rr
					{
						border-top:   30px solid red;
						border-right: 30px solid red;
					}

					.date-item-triangles-gg
					{
						border-top:   30px solid green;
						border-right: 30px solid green;
					}

					.date-item-triangles-rg
					{
						border-top:   30px solid red;
						border-right: 30px solid green;
					}

					.date-item-triangles-gr
					{
						border-top:   30px solid green;
						border-right: 30px solid red;
					}
					.date-item-triangles-cc
					{
						border-top:   30px solid burlywood;
						border-right: 30px solid burlywood;
					}

					.date-item-disabled
					{
						background-color: #404040;
						position:         absolute;
						top:              0;
						left:             0;
						width:            100%;
						height:           100%;
						opacity:          0.7;
						z-index:          1000;
					}
				}
			}
		}
	}
}
