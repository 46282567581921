body.booking.booking_free
{
	.page_wrapper
	{
		.content-wrapper
		{
			padding: 0;

			.booking_free-free_date_list-wrapper
			{
				padding: 16px 16px 0 0;
				height:  100%;
				@media (min-width: 768px)
				{
					border-right: @navbar-border-bottom-height solid @dark_color;
				}

				.booking_free-free_date_list-heading
				{
					margin: 0;
				}

				// .booking_free-free_date_list-heading + hr {
				//     border-color: @primary_color;
				// }
				.booking_free-free_date_item-wrapper
				{

					.booking_free-free_date_item-date-wrapper
					{
						display:         flex;
						justify-content: space-between;

						.booking_free-free_date_item-date
						{

						}
					}

					.booking_free-free_date_item-note-wrapper
					{
						.booking_free-free_date_item-note-heading
						{

						}

						.booking_free-free_date_item-note
						{

						}
					}
				}
			}

			.booking_free-form
			{
				padding: 16px 0;

				.subform-title
				{
					margin: 0 0 16px;
				}

				.booking_free-form-buttons
				{
					display:         flex;
					justify-content: flex-end;

					.booking_free-form-buttons-submit
					{
					}
				}
			}
		}
	}
}