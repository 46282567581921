
body#booking.booking.booking_summary
{
	.fieldset-style()
	{
		border: 1px solid rgb(43, 137, 0);
		legend
		{
			font-size: 20px;
			color:     rgb(43, 137, 0);
			margin:    0;
			width:     auto;
		}
	}
	.table_in_fieldset-style()
	{
		width:     100%;
		max-width: 100%;
		tbody
		{
			tr
			{
				td, th
				{
					&:nth-child( 1 )
					{
						text-align: left;
					}

					&:nth-child( 2 )
					{
						text-align: right;
					}

					padding: 8px;
				}
			}
		}
	}

	.container-fluid
	{
		> .row
		{
			margin-bottom: -16px;

			.note
			{
				.fieldset-style();
				flex:          1 1 auto;
				margin-bottom: 16px;
			}

			#customer_data-wrapper
			{
				display:   flex;
				flex-flow: column nowrap;
				height:    100%;

				fieldset.customer_data
				{
					margin-bottom: 16px;
					.fieldset-style();

					table
					{
						.table_in_fieldset-style();
					}
				}

				.note
				{

				}
			}
			
			#prices_wrapper
			{
				height:    100%;
				display:   flex;
				flex-flow: column nowrap;

				#prices-wrapper
				{
					.fieldset-style();
					padding:       16px 16px 0;
					margin-bottom: 16px;

					.table-heading
					{
						margin:    0;
						font-size: 18px;
					}

					table.prices-section
					{
						.table_in_fieldset-style();
						margin-bottom: 16px;
					}
				}

				.note
				{

				}
			}
		}

		.table-days
		{
			width: 100%;
			colgroup
			{
				col.first
				{
					background-color: fade(@secondary_color, 50%);
				}
				col.second
				{
					background-color: fade(@color1, 50%);
				}
			}
			//tbody, thead, tfoot
			//{
			//	width: 100%;
			//}
		}
	}
	
	
}
