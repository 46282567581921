body#contact
{
	nav.main_navbar
	{
		ul
		{
			li.contact
			{
				.active-link();
			}
		}
	}

	nav.footer-navbar
	{
		> ul
		{
			> li.nav-item.contact
			{
				.active-link();
			}
		}
	}

	section
	{
		padding: 20px 0 0;

		.contact-page-wrapper
		{
			padding: 0;
			#page_heading
			{
				text-align: center;
				margin:     0;
			}
			
			#contact-data
			{
				background-color: 	fade(@primary_color, 100%);
				padding:          	16px;
				height: 			100%;
				
				*
				{
					color: white;
				}
				
				.contact-heading
				{
					font-weight: 700;
					font-size:   24px;
					margin:      0 0 8px;
				}
				
				.contact-body
				{
					margin: 8px 0 0;
					
					div
					{
						span
						{
							font-size: 18px;
						}
					}
				}
			}
			
			#map-wrapper
			{
				margin:   0 0 16px;
				position: relative;
				width:    100vw;

				#map
				{
					width:  100%;
					height: 450px;
				}

				
				#map-overlay
				{
					z-index:          10;
					position:         absolute;
					
					*
					{
						color: white;
					}

					@media (max-width: 991px)
					{
						position: static;

					}
				}
			}

			.contact-description-heading
			{
				text-align: center;
				color:      @primary_color;
			}
			
			//.contact-description-wrapper
			//{
			//	display: 			flex;
			//	flex-flow: 			row nowrap;
			//	justify-content: 	space-between;
			//	align-items: 		stretch;
			//	//align-content: 		;
			//
			//	#contact-data,
			//	.contact-description-item-wrapper
			//	{
			//		flex: 		0 0 auto;
			//		height: 	256px;
			//		max-width: 	100%;
			//	}
			//}

			.row
			{
				// div.contact-wrapper{
				//   .contact-heading {
				//     .contact-heading-small();
				//   }
				//   .contact-body {
				//     margin: 8px 0 0;
				//     div{
				//       span{
				//         font-size: 18px;
				//       }
				//     }
				//   }
				// }
				#contact-data-wrapper
				{
				}
				.contact-description-item-wrapper
				{
					padding: 0 8px;
					margin:  0 0 16px;

					.contact-description-item-heading
					{
						.contact-heading-small();
					}

					.contact-description-item-text
					{
						text-align: justify;
						margin:     8px 0 0;
					}
				}
			}

		}

	}
}
